.dataTableDiv {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;
    text-transform: capitalize;
    color: #22252A;
    margin-top: 20px;
  }
  
    .WOlink{
      color:var(--link-color) !important;
      font-weight: bold !important;
      cursor: pointer;
    }
  
    .dataGridText{
      font-weight: 400;
    }
  
    .alignCenter{
      text-align: center;
    }
  
    .danger{  
      color:var(--icon-color-danger) !important;
    }
  
    .dataGridIcon{
      font-size: 27px !important;
      cursor: pointer;
      margin: -10px 0 !important;
    }
  
    .primary{  
      color:var(--icon-color-primary) !important;
    }
  
    #gridContainer {
      max-width: none !important;
    }
  
    /* .dx-toolbar-after {
      right: 25px !important;
      margin-top: 5px;
  } */
  
  .dx-datagrid-search-panel {
    margin-left: 0% !important;
  }
  
  .status-style {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 16px;
    gap: 10px;
    width: 80px;
    height: 24px;
    box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
    border-radius: 56px;
  }

  .dx-datagrid-nowrap, .dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content{
    white-space: normal !important;
  }

  .dx-datagrid-scroll-container:not(.dx-popup-content .dx-datagrid-scroll-container){
    background: #253030;
    color: #fff;
    /* filter: drop-shadow(3px -5px 40px rgba(0, 0, 0, 0.10)); */
    flex-shrink: 0;
    padding:20px 10px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .dx-datagrid .dx-datagrid-headers .dx-header-row > td{
    border-bottom:none !important;
  }
  .dx-datagrid-pager{
    padding:15px !important;
  }
  .search-container .dx-widget {
    height:50px !important;
    border-radius: 16px !important;
  }

  .dataTableDiv .dx-datagrid{
    background-color: transparent;
  }

  .dx-editor-outlined.dx-texteditor-with-floating-label.dx-lookup-empty .dx-texteditor-label .dx-label, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-texteditor-label .dx-label{
    color: #22252A;
    font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 100% */
text-transform: capitalize;
  }

  .search-container .dx-dropdowneditor-input-wrapper{
    border-radius:16px;
  }

  .dataGridText  button.datatable.dummyButtons {
    color: #FFF;
  text-align: center;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
  border-radius: 8px;
  box-shadow: 3px -5px 40px 0px rgba(205, 205, 212, 0.10);
  padding:5px;
  width:100px;
  }

  .dx-datagrid-content .dx-datagrid-table .dx-row > td, .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td{
    vertical-align: middle !important;
  }
  .dx-datagrid-pager {
    border-top: none !important;
  }

  .dataGridText p.subText{
    color: #979797;
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .dataGridText p.combinedYear a{
    color: #00B86B;
    font-family: Rubik;
    font-style: normal;
    font-weight: 800;
    line-height: 20px; /* 125% */
    text-transform: uppercase;
    margin-left: 5px;
  }
  .dataGridText p {
    margin: 0;
  }

  .dataGridText p:last-child{
    margin-top:-10px;
    margin:0;
  }

  .dx-datagrid-rowsview .dx-row:not(.dx-popup-content .dx-datagrid-rowsview .dx-row){ 
    border-bottom: 3px dotted rgba(0, 0, 0, 0.20) !important;
  }

  .dx-datagrid-rowsview .dx-row:last-child{
    border: none !important
  }

  .dx-datagrid-adaptive-more:before{
    content: "\f00d" !important;
  }

  .dx-datagrid-rowsview .dx-row{
    padding: 0!important;
}

.dx-datagrid .dx-data-row > td:not(.dx-popup-content .dx-datagrid .dx-data-row > td, .adminUser-container.dueDates .dx-datagrid .dx-row > td), .dx-datagrid .dx-data-row > tr > td:not(.adminUser-container.dueDates .dx-datagrid .dx-row > td){
    padding: 10px 7px !important;
}


.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-adaptive{
  padding: 0 20px !important;
}

.dx-master-detail-row{
    margin-top: -61px;
    position: absolute;
    right: 45px;
    /* border: 2px dotted  rgba(0, 0, 0, 0.20) !important; */
    border: none !important;
    box-shadow: 3px -5px 40px 0px rgba(205, 205, 212, 0.1);
}

.dx-master-detail-row > td {
  background-color: #d4d4d4 !important;
}
.dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell, .adminUser-container.dueDates .dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell{
  padding: 20px 20px !important;
}

.dx-overlay-content .dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox{
  display: inline-block !important;
}

.adminUser-container.dueDates .dx-datagrid .dx-row > td {
padding: 20px 7px !important;
}

.adminUser-container.dueDates .dx-datagrid-scrollable-simulated.dx-datagrid-content .dx-datagrid-table .dx-row > td {
padding: 7px !important;
}

.adminUser-container.dueDates .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-adaptive {
  padding: 0 20px !important;
  }

.dataTable.danger.dataGridIcon{
  margin-left:10px !important;
}