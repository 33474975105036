
.message-box {
    margin-top: 50px;
    margin-left: 20px;
    color: #222a2a;;
    font-family: Rubik;
    font-weight: 300;
  }

  .message-box h1 {
    font-size: 60px;
    line-height: 46px;
    margin-bottom: 40px;
  }

  .buttons-con .action-link-wrap {
    margin-top: 40px;
  }
  .buttons-con .action-link-wrap a, button.backToHome.primary, .dx-button-mode-contained.dx-button-success, .dx-button-mode-contained.dx-button-success.dx-state-hover{
    background: #00B86B;
    color: #fff !important;
    padding: 10px 15px !important;
    border-radius: 16px;
    font-weight: 700;
    font-size: 14px;
    transition: all 0.3s linear;
    cursor: pointer;
    text-decoration: none;
    margin-right: 10px;
    text-transform: uppercase;
  }
  .buttons-con .action-link-wrap a:hover {
    background: #5A5C6C;
    color: #fff;
  }
  
  #Polygon-1 , #Polygon-2 , #Polygon-3 , #Polygon-4 , #Polygon-4, #Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
  }
  #Polygon-2 {
    animation-delay: .2s; 
  }
  #Polygon-3 {
    animation-delay: .4s; 
  }
  #Polygon-4 {
    animation-delay: .6s; 
  }
  #Polygon-5 {
    animation-delay: .8s; 
  }
  .NotFoundHeading{
    font-size: 7.5em;
    font-weight: bold;
    padding-bottom:20px;
  }
  .NotFoundText{
      font-size: 1.2em;
      line-height: 24px;
      margin-right: 30px;
  }
  @keyframes float {
      100% {
      transform: translateY(20px);
    }
  }
  @media (max-width: 450px) {
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -250px;
      margin-left: -190px;
    }
    .message-box {
      top: 50%;
      left: 50%;
      margin-top: -100px;
      margin-left: -190px;
      text-align: center;
    }
  }