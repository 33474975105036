@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Rubik";
  src:
    local("RubikBold"),
    url("./fonts/Rubik/Rubik-Bold.ttf") format("truetype");
  font-weight: bold;
}

:root {
  --color-primary: #009568;
  --color-prime: #222a2a;
  --color-secondary: #f2f2f2;
  --color-accent: #01244e;
  --color-default: #ffffff;
  /* --color-accent: #004256; */
  --font-color: #000000;
  --font-color-light: #999;
  --font-color-inverse: #ffffff;
  --border-color: #c0c0c0 !important;
  --scroll-color: #d8d7d7;
  --scroll-hover-color: #919191;
  --link-color: #0078d4;
  --icon-color-danger: #f55e5e;
  --icon-color-primary: #004256;
  --icon-color-default: #333;
  --button-danger: #f55e5e;
  --button-primary: #01244e;
  --button-default: #eee;
  transition: 0.4s ease-in-out;
  --rgba: rgba(255, 255, 255, 1);
  --rgba-chart: rgba(0, 0, 0, 1);

  /* font styles */
  --base-font-family: "Rubik";
  --font-weight-3: 300;
  --font-weight-4: 400;
  --font-weight-6: 600;
  --font-weight-8: 800;
  --font-size-xs: 8px;
  --font-size-sm: 10px;
  --font-size-md: 12px;
  --font-size-lg: 14px;
  --font-size-xl: 16px;
  --line-height-sm: 18px;
  --line-height-md: 20px;
  --line-height-lg: 40px;
}

.ml-2 {
  margin-left: 2px;
}

.mtl-20 {
  margin: 20px 20px 0;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px !important;
}
.m-0 {
  margin: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-20 {
  margin-top: 20px;
}
.m-10 {
  margin: 10px;
}
.m-20 {
  margin: 20px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.p-10 {
  padding: 10px;
}
.p-20 {
  padding: 20px;
}
.ptlr-20 {
  padding: 20px 20px 0;
}
.pr-20 {
  padding-right: 20px;
}
.align-right {
  text-align: right;
}

body,
.layout-page-content,
.dx-widget{
  padding: 0;
  margin: 0;
  font-family: var(--base-font-family) !important;
  font-style: normal;
  font-weight: var(--font-weight-4);
  font-size: var(--font-size-md);
  line-height: var(--line-height-sm);
  text-transform: capitalize;
}

html,
body,
.MuiTypography-root,
.MuiTab-root,
.label,
.MuiFormLabel-root {
  font-family: var(--base-font-family) !important;
}

html,
body {
  min-height: 100%;
  background-color: var(--color-primary);
}

.label,
.MuiFormLabel-root {
  transform: translate(0, -4px) scale(0.75);
  color: #22252a !important;
  font-family: "Rubik" !important;
  text-transform: capitalize !important;
  line-height: 20px;
}

.MuiTab-root {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
}

.page-layout .MuiDrawer-root.MuiDrawer-docked,
.page-layout .MuiPaper-root.MuiDrawer-paper,
*.MuiPaper-root-MuiDrawer-paper {
  /* background-color: #222a2a !important; */
  color: #fff !important;
  /* overflow: hidden !important; */
}

.layout-page-content.full-width {
  width: 100%;
}

.MuiDrawer-paper.MuiDrawer-paperAnchorLeft{
  background-color: #222a2a !important;
  padding: 10px;
}

a,
.complete-score {
  color: #00b86b;
}

.complete-score{
  margin-top: 2px;
  font-size: 14px;
}

.MuiDrawer-root.MuiDrawer-docked > div {
  background-color: var(--color-prime) !important;
  color: #fff !important;
  overflow: hidden !important;
}

code,
.dx-widget,
.dx-checkbox-text,
.dx-widget input,
.dx-widget-textarea,
.dx-texteditor-input {
  font-family: "Rubik" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px !important;
  text-transform: capitalize;
  color: #222a2a !important;
}

code,
.dx-widget,
.dx-widget-textarea,
.dx-texteditor-input {
  text-transform: none !important;
}

.bold {
  font-weight: 700 !important;
  line-height: 20px;
}

h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: normal;
  letter-spacing: -0.72px;
}

h3 {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #1f1f20;
}

a.subNavItem {
  color: var(--color-default);
  text-decoration: none;
}

.dx-texteditor.dx-editor-outlined {
  background: #eef1f0 !important;
  color: #000000;
  border: none !important;
  box-shadow: 3px -5px 40px 0px rgba(205, 205, 212, 0.1);
  border-radius: 12px !important;
}

nav.MuiList-subheader {
  padding-bottom: 40px;
}

.page-layout {
  /* color: #222a2a !important;
  background: #141414;
  background-color: #222a2a; */
  display: flex;
  height: 100%;
}

.layout-navigation {
  color: var(--color-default) !important;
  margin: 20px 0 40px 20px;
  display: flex;
  /* border-radius: 32px; */
}

/* .layout-navigation .MuiDrawer-root {
  border-radius: 32px;
} */

.layout-navigation svg.openIcon {
 padding: 20px 0;
cursor: pointer;
}

.page-main-content {
  background: #f5f5f5;
  background-color: #f5f5f5;
  /* background-color: #eef1f0; */
  border: 1px solid #f5f5f5;
  border-radius: 50px;
  margin: 20px 20px;
  min-height: calc(100vh - 40px) !important;
}

.MuiPaper-root.MuiPaper-elevation {
  position: relative !important;
  /* background-color: #fff !important; */
  box-shadow: 3px -5px 40px 0px rgba(205, 205, 212, 0.1);
  color: #222a2a !important;
  /* margin-bottom: 5px; */
}

.layout-navigation .MuiPaper-root.MuiPaper-elevation {
  border-radius: 32px !important;
  /* padding: 10px; */
} 

.sectionDesktop {
  float: right;
  display: inline-flex;
    align-items: center;
}

.appBarTitle > h2 {
  margin-top:0px;
}
header.MuiPaper-root.MuiPaper-elevation {
  background-color: var(--color-primary) !important;
  position: sticky !important;
  top: 0 !important;
  left: auto !important;
  right: 0 !important;
  box-shadow: none;
  padding: 0.5rem 1.2rem;
  color: var(--color-default) !important;
  min-height: 64px;
  margin-top:10px;
}

.scrolledAppBar > .MuiToolbar-root {
  /* background: #eef1f0 !important;
  color: #222a2a !important; */
  /* transition: padding 0.25s ease-in-out;
  box-shadow: 0.25s ease-in-out;
  backdrop-filter: 0.25s ease-in-out; */
  padding: 10px;
}

.horizontal-line-container{
  max-width: 100%; /* Set a maximum width for the container */
  margin: 0 auto;
}

.horizontal-line-container svg {
  width: 100%;
}

.scrolledAppBar .sectionDesktop button && :not(.scrolledAppBar .sectionDesktop .userInfo button){
  color: #222a2a !important;
}

.scrolledAppBar button.unitNameButton {
  color: #fff !important;
}

.layout-content-wrapper {
  margin: 10px 0px;
  min-height: calc(100vh - 42px) !important;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.navigation-header {
  /* height: 100px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layout-page-content {
  background-color: #eef1f0 !important;
  background: #eef1f0 !important;
  border: 1px solid #eef1f0;
  border-radius: 50px;
  flex-grow: 1;
  /* width: 100%; */
  /* padding: 1.5rem; */
  transition: padding 0.25s ease-in-out;
  /* margin-left: auto;
  margin-right: auto; */
  max-width: 100%;
  margin: 20px;
  position: sticky !important;
  top: 0 !important;
  left: auto !important;
  right: 0 !important;
  padding: 0.5rem 1.5rem 0 1.5rem;
  color: rgba(76, 78, 100, 0.87);
  min-height: 64px;
  margin-bottom:10px;
  overflow-y: auto;
}

.layout-page-content > .MuiPaper-root.MuiPaper-elevation {
  width: 100%;
  background-color: #eef1f0 !important;
  color: #1f1f20 !important;
  transition: none !important;
  /* border-radius: 0px !important; */
  box-shadow: none !important;
}

.ui-container,
.results-container {
  background-color: #fff;
  color: rgba(76, 78, 100, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 32px;
  box-shadow: 3px -52px 48px 0px rgba(205, 205, 212, 0.1);
  margin: 20px 0;
  padding: 10px;
}

.reports-container, .adminUser-container {
  background-color: #fff;
  color: rgba(76, 78, 100, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 32px;
  box-shadow: 3px -52px 48px 0px rgba(205, 205, 212, 0.1);
  margin: 0;
  padding: 0px;
}

.reports-container .dataTableDiv {
  margin: 0 !important;
}

.ui-container .MuiTypography-root.MuiTypography-body1 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  padding: 0;
}
.ui-container .MuiTypography-root {
  color: #22252a;
  font-family: "Rubik" !important;
  font-style: normal;
  line-height: 18px !important;
}

.sectionDesktop button {
  color: var(--color-default);
}

.sectionDesktop .userInfo button {
  color: white;
  border-radius: 50px;
  background: #047050;
  /* padding: 20px 20px; */
  min-width: auto;
  margin-left: 10px;
  font-weight: 400
}

#primary-search-account-menu .MuiPopover-paper{
  left: unset !important;
  right: 37px !important;
  float: right !important;
  height: auto !important;
  top: 100px !important;
  opacity: 0.9 !important;
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 12px !important;
  padding: 5px 10px !important;
}

#primary-search-account-menu .MuiPopover-paper p {
  padding: 0 10px;
  text-transform: none;
  font-size: 14px;
  /* text-align: right; */
}

#primary-search-account-menu .MuiPopover-paper li {
  padding: 10px;
  font-size: 14px;
  margin-top: -10px;
  font-family: var(--base-font-family) !important;
  color: #009568;
  font-weight: 600;
}

.score-heading {
  display: flex;
  justify-content: space-between;
}

.score-heading .view-all-scorecards a {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-decoration: none;
  color: #009f5c;
}

.score-card svg.vetlicalLineIcon{
  height:40px;
  margin:0 10px;
}

/* Scores page css */
.header {
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
}

.header-values {
  font-style: normal;
  line-height: normal;
}
.score-card .header {
  color: #222a2a;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
  vertical-align: middle;
}

.score-card .header-values {
  font-size: 16px;
  font-weight: 700;
  display: inline-flex;
  vertical-align: middle;
  color: var(--Coal, #222A2A);
    font-family: Rubik;
    font-style: normal;
    line-height: 28px;
    text-transform: capitalize;
    display: inline-flex;
    align-items: center;
}

.solidVerticalDivider{
  color: #C3C3C3;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin:0 5px;
}

.score-cards:last-child{
  padding-bottom: 20px;
}

.score-card .header-values.unit {
  font-size:12px;
}

.score-card .header-values.score {
  text-transform: uppercase;
  font-size: 14px;
}
.score-card .header-values.status-buttons button, .score-card-detail .header-values.status-buttons button {
  color: #FFF;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
}

.score-item-year {
  color: #00b86b;
  font-weight: 900;
  text-decoration-line: underline;
  color: #00B86B;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}

.score-item-id{
  color: #253030;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main-heading {
  margin-bottom: 20px;
}

.status-button {
  align-self: center;
}

.MuiDivider-flexItem {
  margin: 0 10px !important;
  border-color: #c3c3c3 !important;
  border-width: 2px !important;
}

.MuiDivider-flexItem.dotted-line {
  border-style: dotted !important;
  border-width: 1px !important;
  margin: -10px 10px !important;
}

a.subNavItem li {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

a.subNavItem.active li {
  border-radius: 24px;
  background: #2e3c3c;
  width: 90%;
  margin: 10px 0;
}

a.subNavItem span.MuiTypography-root{
  color: #FFF;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
  text-transform: uppercase;
  padding: 10px 0;
}

.ui-container.score-card-detail{
  box-shadow: none !important;
}

.scorecards-main{
  /* height: 100%; */
  padding-bottom: 20px;
}

.ui-container.score-card-detail, .GESSummaryPaper {
  display:inline-block;
  height: calc(100% - 20px);
}

.ui-container.score-card-detail, .scoreCardsGrid{
  margin-top: 0px !important;
}

.score-card-detail > div {
 padding-top:10px;
}

.score-card-detail .header {
  color: #000;
  line-height: 20px;
  font-weight: 500;
  padding-right: 5px;
}

.score-card-detail .header-values {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  display: inline-flex;
  vertical-align: middle;
}
.score-card-detail .header-values.cardId {
  padding-left: 5px;
}

.score-card-detail .header-values.year {
  font-family: Inter;
  font-size: 20px;
  font-weight: 900;
  text-decoration-line: underline;
  margin: 5px 0 10px 0;
}

.score-card-detail .header-values.unit {
  display:flex;
  color: #000;
  font-family: Rubik;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  text-transform:uppercase;
  margin-top:0px;
}

.score-card-detail p.daysLeft{
  color: #000;
  font-family: Rubik;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  text-transform:uppercase;
}

.score-card-detail p.daysLeft span.value {
  color: #FC5B00;
  font-weight:800;
}

.score-card-detail .header-values.unit .value {
  margin-left:5px;
  color: var(--Coal, #222A2A);
  /* font-size: 16px;
  line-height: 28px; */
  text-transform: capitalize;
  font-weight:700;
}

.score-card-detail .header-values.status {
  padding: 5px;
}

.score-card-detail .ra-title {
  color: #222a2a;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-transform: capitalize;
  margin: 0px;
}

.score-card-detail .score-status {
  color: #253030;
  margin: 0;
  font-size: 20px;
  text-transform: uppercase;
}

.score-card-detail .ra-values {
  font-weight: 700;
  padding-left: 5px;
}

.search-container {
  margin: 30px 0;
}

.dummyButtons {
cursor:auto;
pointer-events: none;
}

.search-container .dx-texteditor-container {
  background: #fff;
}

.search-container .btn {
  display: flex;
  border: none;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 50px;
  cursor: pointer;
  flex-shrink: 0;
}

.search-container .btn-primary {
  background: #00b86b;
  color: #fff;
  text-transform: uppercase;
}

.search-container .btn-export {
  color: #000;
  border: 2px solid #253030;
  background: inherit;
  text-transform: capitalize;
  margin-left: 10px;
}

.search-container .btn-export img {
  width: 32px;
  height: 32px;
  padding-right: 5px;
}

.search-container .filter-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ui-container .filter-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ui-container.admin-user {
  padding: 40px 0;
}

.ui-container.admin-user .MuiGrid-root.MuiGrid-container, .ui-container.admin-user .MuiGrid-root.MuiGrid-item.toggle{
  display: flex;
  justify-content: center;
}

.GESDetailsPaper{
  padding: 15px;
  margin-bottom:20px;
}

.accordion-container > .MuiAccordion-root{
  border-radius: 12px;
    background: #EEF1F0;
    box-shadow: 3px -5px 40px 0px rgba(205, 205, 212, 0.10);
    margin: 0;
}

.accordion-container .MuiAccordionSummary-content {
  margin:0 !important;
}

.accordion-container p{
  text-transform: none !important;
}

.GESDetailsContainer .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters{
  background: #D7DBDA;
  min-height: auto;
}

.GESDetailsContainer .indicator-desc-titles{
  color: #253030;
  font-family: Rubik;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
}

.main-title{
  color: #253030;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
}

.raWithScores {
  display: flex;
  justify-content: space-between;
}

.raWithScores h4 {
  color: #253030;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
  max-width: 50%;
}

.raWithScores .response-section{
  display: flex;
}

.raWithScores .response-section .prev-response{
  margin-right: 5px;
  color: #67A587;
  font-weight: 700;
  /* line-height: 24px; */
}

.raWithScores .response-section .current-response{
 display: inline-flex;
}

.raWithScores .response-section .current-response > p{
  margin-right:5px;
}

.raWithScores .radio-container .MuiRadio-root{
  padding-right: 2px !important;
}

/* .raWithScores .radio-container .MuiRadio-root.Mui-checked{
  color: #253030 !important;
}

.raWithScores .radio-container .MuiFormControlLabel-root .MuiSvgIcon-root{
  font-size: 1.4rem !important;
} */

.raWithScores .radio-container .MuiFormControlLabel-root{
  margin-left: -8px!important;
  margin-right: 8px !important;
}

.raWithScores .radio-container .MuiRadio-root svg {
  width: 20px;
  height: 20px;
}

.raWithScores .radio-container .radio-label {
  display: inline-flex;
  margin-right: 10px; /* Adjust as needed */
}

.raWithScores .radio-container .radio-input:checked {
  background-color: #253030 !important;
}

.tabs-section, .MuiTabs-root{
  padding: 0px !important;
  display: flex !important;
  overflow-x: auto !important;
}

.tabs-section button {
  min-width: 120px; 
  flex:auto;
}


@media (min-width: 768px) {
  .tabs-section {
    overflow-x: hidden !important; 
  }
}
 .tabs-section .MuiTabs-scroller {
  /* overflow-x: auto !important; */
  white-space: normal !important;
  word-wrap: break-word !important;

}

.tabs-section button{
  background: #fff;
    border: none;
    box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
    border-radius: 16px;
    margin-right: 10px;
    white-space: normal !important;
    /* word-wrap: break-word !important;
    overflow-wrap: break-word !important; */
    display: flex;
    flex-wrap: initial !important;
}

.tabs-section button:last-of-type {
  margin-right: 0 !important;
}

.tabs-section .Mui-selected{
  background: #222a2a !important;
  color: #fff !important;
}

.tabs-container .MuiTabs-flexContainer{
    border-radius: 12px;
    display: flex;
}

.tabs-container .MuiGrid-root{
  align-items: baseline;
  margin-bottom: 10px;
}

.tabs-container .MuiTabs-indicator{
  display:none;
}

.summaryPaper {
  padding: 10px;
}

.mainContent {
  margin-bottom: 50px;
  position:relative;
  padding-top: 20px;
}

.tabs-container {
  position: relative;
}

.tab-label {
  display: flex;
  align-items: center;
  position: relative;
}

.tab-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* Adjust as needed */
  height: 30px; /* Adjust as needed */
  background-color: #f0f0f0; /* Set a default background color */
  border-radius: 50%;
  border: 2px dotted #999; /* Set a default dotted line color */
  font-size: 14px; /* Adjust as needed */
  margin-right: 5px; /* Adjust as needed */
  color: #333; /* Set a default text color */
  position: absolute;
  top: 100%; /* Position it below the tab label */
  transform: translateX(-50%); /* Center horizontally */
}

.tab-number.active {
  background-color: #4caf50;
  border-color: #4caf50; 
  color: #fff;
}

.tab-number.error {
  background-color: #ff5722; 
  border-color: #ff5722; 
  color: #fff; 
}

.gesButtonContainer {
  position: fixed;
  bottom: 0px;
  left: 295px;
  right: 45px;
  /* left: calc(10vw - 80px); 
  right: calc(2vw + 5px);  */
  display: flex;
  justify-content: space-between;
  background-color: #eef1f0 !important;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  height: 100px;
  /* transition: bottom 0.5s ease; */
}

.gesButtonContainer.bottom{
  bottom: 100px;
  height:auto !important;
}

.navigate-section
 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-section{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* @media screen and (max-width: 768px) {
  .floatingButtons {
    position: static;
    transform: none;
  }
} */

.gesButtonContainer.floatingButtons button {
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  flex-grow: 1;
}

.gesButtonContainer.floatingButtons button:disabled,
.gesButtonContainer.floatingButtons button.primary:disabled {
  background: #979797;
  color: #fff !important;
}

.gesButtonContainer.floatingButtons button.primary {
  background: #00B86B;
  color: #fff !important;
}

.accordion-container p.selected {
  font-weight: 700;;
}

.gesButtonContainer.floatingButtons button.secondary {
  background: #fff;
  border: 1px solid #253030;
  color: #253030 !important;
  border-radius: 16px;
}

.raNameWithScores, .paNameWithScores {
  display: flex;
  justify-content: space-between;
  align-items:center;
}

/* .paNameWithScores {
  position: absolute;
  bottom: 20px;
  left:10px;
  right: 10px;
} */

.raTitle {
  flex: 1;
  margin: 0;
}

.paNameWithScores p{
  margin: 0;
}

.paTitle {
  color: #009568;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 107.143% */
  text-transform: uppercase;
}

.paScores{
  color: #253030;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 107.143% */
  text-transform: uppercase;
}

.paScoreTitle {
  color: #979797;
  margin-left:5px;
}

.raTitle, .raScores, .paTitle, .paScores, .dottedSvgContainer {
  display: inline-block;
  /* margin: 0; */
}

.dottedSvgContainer{
  /* flex: 0 0 auto; */
  margin: 0 10px;
}

/* .raTitle {
  flex-grow: 1;
  margin-right: 10px;
} */

.dottedSvgContainer svg{
  height: 3px;
  width: 100%;
}

.GESSummaryPaper {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 20px !important;
}

.paNameWithScores{
  margin-top: auto !important;
  flex-direction: column;
  padding-top:40px;
}

.paNameWithScores .ScoresTopLine, .paNameWithScores .ScoresTopLine svg {
  width:100%;
}

.paNameWithScores .scoresContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.commentsTitle svg{
  display: inline-flex;
  width: 20px;
  height: 20px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.commentsTitle{
  color: #009F5C;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.button-container {
  display: inline-flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 2px solid #FFF;
}

.button-container >  a{
  text-decoration: none !important;
  color: #fff !important;
}

a.CreateSCButton {
  display: inline-flex;
  gap: 5px;
  align-items: center;
}

.button-container.disabled {
  pointer-events: none;
  opacity: 0.5; /* or any other styles to indicate disabled state */
}

.total-section, .submit-section{
  display: flex;  
  justify-content: space-between;
  border-radius: 16px;
  background: #253030;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 107.143% */
  text-transform: uppercase;
  padding: 0 10px;
}

.total-section p{
  display:inline-flex;
}

.total-section p span{
  margin-left:5px;
}

.submit-section{
background: #00B86B !important;
justify-content: center;
}

.submit-section button{
  color:#fff !important;
  cursor: pointer;
}

.submit-section button svg {
  vertical-align: middle;
    margin-left: 8px;
    width: 24px;
    height: 22px;
}

.response-error-message {
  color: #FF961C;
  text-transform: uppercase;
}

.response-error-message svg {
  vertical-align: middle;
    /* margin-right: 2px; */
    margin-left:8px;
    width: 24px;
    height: 22px;
}

.confirm-section {
  text-align: center;
  background: #fff;
  margin-top: 50px;
  padding: 20px;
}

.layout-page-content.white-bg, .layout-page-content.white-bg > .MuiPaper-root.MuiPaper-elevation {
  background: #fff !important;
  background-color: #fff !important;
}

.confirm-section .unit-details{
 margin-top:40px;
}

.confirm-section .progress-section{
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.confirm-action-buttons .btn {
  color: #253030;
  border-radius: 16px;
  font-family: Rubik;
  border: 2px solid #253030;
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  padding: 10px;
  line-height: 16px;
 } 

 .confirm-action-buttons .btn-primary, .btn-primary:hover {
  background: #00B86B !important;
  background-color: #00B86B !important;
  color: #fff;
  border: 2px solid #00B86B;
  margin-left: 10px;
}

.confirm-action-buttons .btn-danger {
  background: #FF3E3E !important;
  background-color: #FF3E3E;
  color: #fff;
  border: 2px solid #FF3E3E;
  margin-left: 10px;
}

.confirm-action-buttons .btn-secondary {
  background: #fff;
  color: #253030;
  border: 2px solid #253030;
  margin-left: 10px;
}

.readOnly, .readOnly .comments-title {
  pointer-events: none;
  opacity: 0.5;
}

.navigation-header svg {
  width: 140px;
  height: 140px;
  margin: 0 35px;
}

.navigation-header svg.closeIcon{
  width: 20px;
  height: 20px;
  cursor: pointer;
}

button {
  transition: opacity 0.3s ease;
}

button:hover {
  opacity: 0.8;
}

button.unitNameButton {
  border-radius: 16px;
  background: #047050;
  box-shadow: 3px -5px 40px 0px rgba(0, 0, 0, 0.10);
  padding: 16px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  color: #FFF;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
}

.comments-section .dx-texteditor-input-container, .GESDetailsPaper .dx-widget input{
  border-radius: 12px;
  /* border: 1px solid #253030; */
  box-shadow: 3px -5px 40px 0px rgba(205, 205, 212, 0.10);
  background: #fff !important;
  text-transform: none !important;
}

.dx-overlay-content.dx-popup-normal{
  text-align: center;
  overflow: visible;
}

.dx-overlay-content.dx-popup-normal p.AlertIcon svg{
  width: 30px;
  height: 30px;
}
.dx-overlay-content.dx-popup-normal p.AlertTitle {
  color: #1F1F20;
  text-align: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
}

.dx-overlay-content.dx-popup-normal p.AlertMessage {
  color: #253030;
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  text-transform: none !important;
}

.dx-overlay-content.dx-popup-normal .dx-button {
  border-radius: 16px;
  background: #00B86B;
  color: #fff !important;
  font-size: 12px;
}

p.deleteCommentTitle{
  cursor: pointer;
}

.dx-overlay-content.dx-popup-normal .dx-button.dx-button-cancel{
  border-radius: 16px;
  border: 2px solid #253030;
  color: #253030;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  background-color: #fff;
  color:#253030 !important;
}

.dx-overlay-content.dx-popup-normal .dx-toolbar-after {
  position: unset !important;
  display:flex !important;
  justify-content: center !important;
}

.score-result-details p.header{
  display: flex;
  align-items: center;
}

.score-result-details, .headerLabel, .totalScorelabel{
  color: #F3F8FF;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 10px;
}

/* .score-result-details .header-values{
  margin-right:30px;
}

.header-values {
  color: var(--Coal, #222A2A);
    font-family: Rubik;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-transform: capitalize;
} */

.gesButtonContainer.floatingButtons.tf {
  display:flex;
  justify-content: flex-end;
  align-items: center;
}

.gesButtonContainer .navigate-section-tf{
  display:flex;
}

.gesButtonContainer .navigate-section-tf button:last-child{
  margin-left: 10px;
}

.radio-container.tf input {
  cursor: default !important;
}

.AdminPA{
  color: #1F1F20;
font-family: Rubik;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.72px;
}

.AdminRA{
  color: #253030;
font-family: Rubik;
font-size: 14px !important;
font-style: normal;
font-weight: 600 !important;
line-height: 20px; /* 111.111% */
text-transform: uppercase;
margin-left: 30px;
margin-bottom: 30px;
}

.AdminRA .dx-checkbox-checked .dx-checkbox-icon{
  background-color: var(--color-primary) !important;
  color: #fff !important;

}

.AdminRA .AdminIndicator .dx-checkbox-checked .dx-checkbox-icon{
  background: #253030 !important;
}


.AdminIndicator{
  color: var(--Coal, #222A2A);
font-family: Rubik;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 100% */
text-transform: capitalize;
margin-left: 30px;
margin-bottom: 15px;
margin-top: 15px;
}

.AdminPAHeader{
  color: #1F1F20;
font-family: Rubik;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.96px;
margin: 30px 0;
}
.actions-section {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.actions-section button{
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 16px;
  padding:20px;
}

.actions-section button.secondary {
  border: 2px solid #253030;
  color: #253030;
  
} 

.header-values.toggle-buttons .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked{
  color: #009568 !important;
}

.header-values.toggle-buttons .MuiSwitch-track{
  background-color: #009568 !important;
}

.actions-section button.primary {
  background: #00B86B;
  color: #fff !important;
} 

.buttonsLHS, .buttonsRHS {
  display: flex;
  gap: 10px;
}

.singleButton {
  display: flex;
  justify-content: flex-end;
}

.singleButton .buttonsRHS {
  width: 150px;
}

.adminUser-container .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  background-color: #253030 !important;
} 

.adminUser-container .dx-datagrid .dx-datagrid-content-fixed {
  background: none !important;
}

/* .adminUser-container .dx-datagrid .dx-datagrid-content {
  background: none !important;
} */

.footer{
  text-align: center;
  padding: 20px 0;
}

.footer-logo{
  width: 100px;
}

.sectionDesktop svg.info-icon-tooltip{
  cursor: pointer;
}

.dx-tooltip-wrapper .dx-overlay-content{
  fill: #253030;
  filter: drop-shadow(3px -5px 40px rgba(0, 0, 0, 0.10));
  background-color: #253030 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 16px !important;
  left: -10px !important;
}

.dx-popover-arrow::after{
  background-color: #253030 !important;
  border: none !important;
}

.dx-popover-arrow {
  right:10px !important;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content{
  width: 400px !important;
  white-space: normal !important;
  color: #FFF!important;
  padding: 20px!important;
  font-family: Rubik!important;
  font-size: 14px!important;
  font-style: normal!important;
  font-weight: 400!important;
  line-height: 24px !important;
  text-align: left!important;
  text-transform: none !important;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content .highlight{
  color: #ECFF10 !important;
  font-weight: 700 !important;
}

.tabs-section button:before {
  border:none;
}

.tabs-section button.emptyRes:before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 25px 25px 0;
  border-style: solid;
  border-color: transparent #C3C3C3 transparent transparent;
}

.tabs-section button.partialRes:before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 25px 25px 0;
  border-style: solid;
  border-color: transparent #FC8800 transparent transparent;
}

.tabs-section button.fullRes:before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 25px 25px 0;
  border-style: solid;
  border-color: transparent #00B86B transparent transparent;
}

.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content,
.dx-calendar-navigator-next-view.dx-button .dx-icon, .dx-calendar-navigator-previous-view.dx-button .dx-icon {
  color: #fff !important;
}

.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content{
  text-align: left;
}

p.no-content{
  text-align: center;
  display:block;
}

/* Tablet css */
@media (max-width: 1024px) {
  .navigation-header svg{
    margin:0 10px;
  }

  .navigation-header svg.closeIcon{
    display: flex;
    align-self: start;
    margin-top: 10px;
  }

  .score-card-detail .ra-values {
    display: block;
    margin-top: -10px;
  }

  .dx-tooltip-wrapper .dx-overlay-content .dx-popup-content{
    padding: 12px 17px !important;
  }

  .MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled {
    opacity: 0.3;
  }

  .MuiTabScrollButton-root.MuiTabScrollButton-horizontal.MuiTabs-scrollButtons > svg {
    font-size:28px;
  }

  .search-container .filter-buttons{
    margin-top: 20px;
  }

  .gesButtonContainer {
    left: 20px;
  }

  .layout-page-content {
    margin-left:-40px;
  }

  .scrolledAppBar {
    width: 105% !important;
    margin-left:-40px;
  }
}

@media only screen and (orientation: portrait) and (max-width:1024px) {
  .raWithScores h4 {
    max-width: 100% !important;
  }
  .raWithScores .response-section {
    display:block !important;
    flex: none !important;
    margin-left: 15px !important;
  }
  .raWithScores .response-section .prev-response p{
    float:right !important; 
  }
}

/* Handle Tablet portrait mode exceptions */
@media only screen and (orientation: landscape) and (min-width:1180px) and (max-width: 1366px) {
  .gesButtonContainer {
    left: 80px;
  }
}

@media only screen and (orientation: landscape) and (max-width: 1366px) {
  header.MuiPaper-root.MuiPaper-elevation{
    padding: 0 1.2rem !important;
    margin-bottom: 0 !important;
  }
  .appBarTitle > h2 {
    margin-bottom: 0px !important;
  }
  .layout-page-content{
    margin-top:10px !important;
    margin-bottom: 10px !important;
  }
  .gesButtonContainer.floatingButtons {
    height: auto !important;
    padding: 20px !important;
    min-height: 20px !important;
  }
}