/* .globalScores-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.globalScores-container svg{
    width: 250px;
    height: auto;
} */

/* @media screen and (max-width: 2500px) {
    .globalScores-container svg{
        width: 250px;
        height: auto;
    }
} */

.globalScores-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
}

.globalScores-container .dxc-labels-group {
    display: none;
}

text {
    font-family: Rubik !important;
    font-style: normal;
}

.dxc-title text{
    color: var(--Coal, #222A2A) !important;
    fill: var(--Coal, #222A2A) !important;
    font-size: 16px !important;
    font-weight: 700  !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
}

.dxc-title text:last-child{
    color: #979797 !important;
    fill: #979797 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 24px !important;; 
    text-transform: none !important;
}

.customClass text, .customClass tspan{
    font-family: Rubik !important;
    display:flex !important;
    flex-wrap: wrap !important;
    color: #FFF;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
}

@media screen and (min-width: 1200px) {
    .customClass text, .customClass tspan{
        font-size: 18px !important;
        font-weight: 700 !important;
        line-height: 24px !important;
    }
}

/* @media screen and (min-width: 1200px) {
    .globalScores-container svg{
        width: 400px !important;
        height: 400px !important;
    }
} */

 .globalScores-container .dxc-item text, .globalScores-container .dxc-item tspan {
    fill: #222A2A  !important;
    font-size: 12px !important;
    font-weight:700 !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    /* text-anchor: middle !important; */
} 

.globalScores-container .dxl-marker {
    display: none !important;
}

.pieWithIcon{
    display: inline-flex;
    align-items: center;
}


.dxc-legend text {
    font-size: 12px !important;
    color: var(--Coal, #222A2A) !important;
    fill: var(--Coal, #222A2A) !important;
    font-weight: 500 !important;    
    line-height: 20px !important;
    text-transform: uppercase !important;
}

.dxc-val-title text, .dxc-arg-title text {
    fill : var(--Coal, #222A2A) !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
}

.dxc-val-elements text, .dxc-arg-elements text {
    fill: #979797 !important;
    text-align: right !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    text-transform: capitalize !important;

}
.priorityAreaDataHighlightsContainer{
    max-width: 900px;
      margin: 0 auto;
  }

  .dxf-title text{
    color: var(--Coal, #222A2A) !important;
    fill: var(--Coal, #222A2A) !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
  }

  .dxf-title text:last-child {
    color: #979797 !important;
    fill: #979797 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    text-transform: none !important;
}